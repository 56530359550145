import React, { useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

// components

import FooterAdmin from "components/Footers/FooterAdmin.js";

//App views

import AdminRoute from "views/admin/AdminView.js"
import ProtectedAdminRoute from 'views/admin/ProtectedAdminRoute.js';


// Admin Views

// import ViewMockExamsInSet from 'views/admin/AdminComponents/ViewMockExamsInSet.js';


// string constants

import * as CONSTANTS from "../../StringConstants.js"
import TlhStaffMainDashboard from 'theLearningHub/tlhViews/TLHStaffMainDashboard.js';
import TLHHeaderComponent from './HelperComponents/TLHHeaderComponent.js';
import TLHSidebar from './HelperComponents/TLHSidebarComponent.js';
import NewEnquiryAssessment from 'theLearningHub/tlhViews/NewEnquiryAssessment.js';
import TLHAdminDashboard from 'theLearningHub/tlhViews/TLHAdminDashboard.js';
import TLHViewOpenEnquiries from 'theLearningHub/tlhViews/TLHViewOpenEnquiries.js';
import TLHViewBookedAssessments from 'theLearningHub/tlhViews/TLHViewBookedAssessments.js';
import TLHClasses from 'theLearningHub/tlhViews/TLHClasses.js';
import IndividualTLHClassView from 'theLearningHub/tlhViews/IndividualTLHClassView.js';
import TLHProspectiveParentsEvenings from 'theLearningHub/tlhViews/TLHProspectiveParentsEvenings.js';
import TLHIndividualParentsEvening from 'theLearningHub/tlhViews/TLHIndividualParentsEvening.js';
import ProtectedGoogleAuthRoute from 'theLearningHub/tlhAuth/ProtectedGoogleAuthRoute.js';
import GoogleLoginComponent from 'theLearningHub/tlhAuth/GoogleLoginComponent.js';
import LoginComponent from 'views/auth/LoginComponent.js';

export default function TLHStaffRoutes() {

  const location = useLocation();
  const isLoginRoute = location.pathname === CONSTANTS.TLHStaffLogin;

  return (
    <>
      <div style={{ display: 'flex', height: '100vh' }}>
        {!isLoginRoute && (
          <div style={{ width: '260px', background: '#f4f4f4' }}>
            <TLHSidebar />
          </div>
        )}
        <div style={{ flex: 1, overflowY: 'auto' }}>
          {!isLoginRoute && <TLHHeaderComponent />}
          <Switch>
            <Route path={CONSTANTS.TLHStaffLogin} component={LoginComponent} />
            <ProtectedAdminRoute path={CONSTANTS.TLHStaffDashboard} exact component={TlhStaffMainDashboard} />
            <ProtectedAdminRoute path={CONSTANTS.TakeNewEnquiry} exact component={NewEnquiryAssessment} />
            <ProtectedAdminRoute path={CONSTANTS.TLHAdminAddObjects} exact component={TLHAdminDashboard} />
            <ProtectedAdminRoute path={CONSTANTS.ViewOpenEnquiries} exact component={TLHViewOpenEnquiries} />
            <ProtectedAdminRoute path={CONSTANTS.ViewBookedAssessments} exact component={TLHViewBookedAssessments} />
            <ProtectedAdminRoute path={CONSTANTS.ViewTLHClasses} exact component={TLHClasses} />
            <ProtectedAdminRoute path={CONSTANTS.ViewTLHClass + "/:tlhClassId"} exact component={IndividualTLHClassView} />
            <ProtectedAdminRoute path={CONSTANTS.TLHParentEvenings} exact component={TLHProspectiveParentsEvenings} />
            <ProtectedAdminRoute path={CONSTANTS.TLHParentEvenings + "/:tlhParentsEveningId"} exact component={TLHIndividualParentsEvening} />

            <Redirect from="/tlh" to={CONSTANTS.TLHStaffLogin } />
          </Switch>
          {/* <Switch>
            <Route path={CONSTANTS.TLHStaffDashboard} exact component={TlhStaffMainDashboard} />
            <Route path={CONSTANTS.TakeNewEnquiry} exact component={NewEnquiryAssessment} />
            <Route path={CONSTANTS.TLHAdminAddObjects} exact component={TLHAdminDashboard} />
            <Route path={CONSTANTS.ViewOpenEnquiries} exact component={TLHViewOpenEnquiries} />
            <Route path={CONSTANTS.ViewBookedAssessments} exact component={TLHViewBookedAssessments} />
            <Route path={CONSTANTS.ViewTLHClasses} exact component={TLHClasses} />
            <Route path={CONSTANTS.ViewTLHClass + "/:tlhClassId"} exact component={IndividualTLHClassView} />
            <Route path={CONSTANTS.TLHParentEvenings} exact component={TLHProspectiveParentsEvenings} />
            <Route path={CONSTANTS.TLHParentEvenings + "/:tlhParentsEveningId"} exact component={TLHIndividualParentsEvening} />
            <Redirect from="/tlh" to={CONSTANTS.TLHStaffDashboard} />
          </Switch> */}
        </div>
      </div>
    </>
  );
};


