import React from 'react';
import { Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import * as CONSTANTS from "../../../StringConstants.js"

function TLHSidebar() {
  return (
    <Drawer variant="permanent" anchor="left" >
      <List>
        {/* Main dashboards where alerts etc will show for following up work */}
        <ListItem>
          <ListItemButton component={Link} to={CONSTANTS.TLHStaffDashboard}>
            <ListItemText primary="Main Dashboard" />
          </ListItemButton>
        </ListItem>

        {/* Page/form to take a new enquiry/assessment */}
        <ListItem>
          <ListItemButton component={Link} to={CONSTANTS.TakeNewEnquiry}>
            <ListItemText primary="New Enquiry / Assessment" />
          </ListItemButton>
        </ListItem>

        {/* View open enquiries */}
        <ListItem>
          <ListItemButton component={Link} to={CONSTANTS.ViewOpenEnquiries}>
            <ListItemText primary="Current Open Enquiries" />
          </ListItemButton>
        </ListItem>

        {/* View all assessments */}
        <ListItem>
          <ListItemButton component={Link} to={CONSTANTS.ViewBookedAssessments}>
            <ListItemText primary="Booked Assessments" />
          </ListItemButton>
        </ListItem>

        {/* View classes/create classes - Check/update registers for each class */}
        <ListItem>
          <ListItemButton component={Link} to={CONSTANTS.ViewTLHClasses}>
            <ListItemText primary="TLH Classes" />
          </ListItemButton>
        </ListItem>

        {/* Inventory page */}
        <ListItem>
          <ListItemButton component={Link} to={CONSTANTS.TLHParentEvenings}>
            <ListItemText primary="Prospectice Parents Evening" />
          </ListItemButton>
        </ListItem>

        {/* Timesheets */}
        <ListItem>
          <ListItemButton component={Link} to={CONSTANTS.TimeSheets}>
            <ListItemText primary="Timesheets" />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}

export default TLHSidebar;
